@import "variables";

/******* Base styles *******/

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-family !important;
  font-size: 14px;
  line-height: 1.5;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  outline: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.table {
  display: flex;
}

.oval-button {
  border-radius: 50px !important;
  width: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-3px {
  height: 30px !important;
}

.w-100 {
  width: 100% !important;
}

.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}

.w-80 {
  width: 80% !important;
}

.w-10 {
  width: 10% !important;
}

.mw-100 {
  max-width: 100%;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.lh {
  line-height: 27px;
}

.theme-container {
  max-width: $theme-max-width;
  margin: 0 auto;
}

.light-block.mat-card {
  background: rgba(0, 0, 0, 0.03) !important;
  box-shadow: none !important;
}

.transition {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.divider {
  border-top: 1px solid rgba(204, 204, 204, 0.6);
}

.mat-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-list-item .mat-list-icon.mat-icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  width: 36px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
}

.mat-icon.caret {
  width: 14px;
}

.mat-button.flex-row-button .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mat-button-wrapper {
  pointer-events: none;
}

.mat-list.reviews .mat-list-item .mat-list-item-content {
  align-items: start;
}

.mat-step-text-label {
  text-transform: uppercase;
}

.mat-tab-label-content {
  font-size: 16px;
}

.mat-row,
.mat-header-row {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 10px !important;
}

.p-2 {
  padding: 20px !important;
}

.p-3 {
  padding: 30px !important;
}

.plr-10 {
  padding: 0px 10px !important;
}

.py-1 {
  padding: 10px 0 !important;
}

.py-2 {
  padding: 20px 0 !important;
}

.py-3 {
  padding: 30px 0 !important;
}

.py-4 {
  padding: 40px 0 !important;
}

.py-5 {
  padding: 50px 0 !important;
}

.px-1 {
  padding: 0 10px !important;
}

.px-2 {
  padding: 0 20px !important;
}

.px-3 {
  padding: 0 30px !important;
}

.px-4 {
  padding: 0 40px !important;
}

.px-5 {
  padding: 0 50px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pr-5 {
  padding-right: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.pl-4 {
  padding-left: 40px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pt-3 {
  padding-top: 30px !important;
}

.pt-4 {
  padding-top: 40px !important;
}

.pt-5 {
  padding-top: 50px !important;
}

.pt-5-5 {
  padding-top: 5px !important;
}


.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.margin-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-3 {
  margin: 30px !important;
}

.m-5 {
  margin: 50px !important;
}

.m-8 {
  margin: 8px !important;
}
.m-10 {
  margin: 10px !important;
}

.mlr-10 {
  margin: 0px 10px !important;
}
.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-10 {
  margin-top: 100px;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.mr-6 {
  margin-right: 60px !important;
}

.mr-7 {
  margin-right: 70px !important;
}

.mr-8 {
  margin-right: 80px !important;
}

.mr-9 {
  margin-right: 90px !important;
}

.mr-10 {
  margin-right: 100px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.ml-6 {
  margin-left: 60px !important;
}

.ml-7 {
  margin-left: 70px !important;
}

.ml-8 {
  margin-left: 80px !important;
}

.ml-9 {
  margin-left: 90px !important;
}

.ml-10 {
  margin-left: 100px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
}

.transparent-input {
  // outline: none !important;
  text-align: center !important;
  // width: 30% !important;
  // border-left: 3px black !important;
  // border-top: 3px black !important;
  // border-right: 3px black !important;
  border: none !important;
  background-color: transparent !important;
  // background-color: #e8e8e8 !important;
}

.d-block {
  display: block;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.border-none {
  border: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.word-wrap-break-word {
  word-wrap: break-word !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-background {
  background-color: #ffffff !important;
}

.wrap-text-into-two-lines {
  display: block;
  /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}

/******* Responsive *******/

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
    .top-menu {
      margin-right: 0;
      a {
        min-width: 56px;
      }
    }
    .sidenav-toggle {
      min-width: 52px;
    }
  }
  .search-form input[type="text"] {
    width: 160px;
  }
  .search-form .categories {
    width: 140px;
  }

  .container-sm,
  .container {
    max-width: 540px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
    .top-menu {
      margin-right: 0;
    }
    .sidenav-toggle {
      min-width: 52px;
    }
  }

  .container {
    max-width: 720px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
    .top-menu {
      margin-right: 0;
    }
    .sidenav-toggle {
      min-width: 52px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #cccccc;
  }
  &:active {
    background: #888888;
  }
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #666666;
  }
  &:active {
    background: #333333;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/******* POINTERS *******/

.cursor-pointer {
  cursor: pointer !important;
}

/******* Background Colors *******/

.transparent-background-color {
  background-color: transparent !important;
}

.red-background-color {
  background-color: #ff0000 !important;
}

.green-background-color {
  background-color: #75aa4c !important;
}

.blue-background-color {
  background-color: #003749 !important;
}

/******* Colors *******/

.green-color {
  color: #75aa4c !important;
}

.blue-color {
  color: #040616 !important;
}

.red-color {
  color: red !important;
}

.black-color {
  color: black !important;
}

.white-color {
  color: white !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #003b49 !important;
}

/******* FONT COLORS *******/

.font-white {
  color: #ffffff !important;
}

.font-black {
  color: #000000 !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-blue {
  color: #040616 !important;
}

.font-green {
  color: #75aa4c !important;
}

/******* MAT SNACK BAR *******/
.mat-snack-bar-container {
  span {
    color: white;
  }
}
.mat-snack-bar-container.success {
  background: #388e3c;
}
.mat-snack-bar-container.error {
  background: #e53935;
}
.mat-snack-bar-container.warning {
  background: #e5b235;
}

.mat-snack-bar-container.information {
  background: #3f85ee;
}

/******* BASIC COLOR *******/
.mat-raised-button.mat-basic {
  background-color: #003749 !important;
  color: #fff !important;
  top: -0.4em;
}
.mat-raised-button.mat-basic:hover {
  background-color: #01597c !important;
  color: #fff !important;
}
.mat-raised-button.mat-basic:disabled {
  background-color: #e0e0e0 !important;
  color: #ababab !important;
}
.mat-raised-button.mat-basic:disabled:hover {
  background-color: #e0e0e0 !important;
  color: #ababab !important;
}

.mat-stroked-button.mat-basic {
  color: #003749 !important;
  border-color: #004763 !important;
  border-width: 1px;
  top: -0.4em;
}

.mat-stroked-button.mat-basic:hover {
  background-color: #fafafa !important;
  color: #004763 !important;
}

/******* SECONDARY COLOR *******/
.mat-ink-bar {
  background-color: #789656 !important;
}

/******* Copyright*******/
.copyright {
  height: 32px;
}

/******* Reduces height of inputs*******/
mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  {
  padding: 0.4em 0px
}
mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.4em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
}
