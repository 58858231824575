/* ======== angular material custom theme ======== */
$md-mscolor: (
    50: #e1e1e3,
    100: #b4b4b9,
    200: #82838b,
    300: #4f515c,
    400: #2a2b39,
    500: #040616,
    600: #030513,
    700: #030410,
    800: #02030c,
    900: #010206,
    A100: #4d4dff,
    A200: #1a1aff,
    A400: #0000e6,
    A700: #0000cd,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$ms-primary: mat-palette($md-mscolor);
$ms-accent: mat-palette($mat-grey, 300, 100, 700);
$ms-warn: mat-palette($mat-red, 500, A200, 800);

// Light theme
$my-custom-theme: mat-light-theme($ms-primary, $ms-accent, $ms-warn);
